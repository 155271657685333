/* src/App.js */
import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { createTodo, deleteTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'

import '@aws-amplify/ui-react/styles.css';
import { Button, Divider, Flex, TextField, withAuthenticator } from '@aws-amplify/ui-react'

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const initialState = { name: '', description: '' }

const App = ({ user, signOut }) => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos))
      const todos = todoData.data.listTodos.items
      console.log('fetched todos =>', todos)
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    setIsLoading(true)
    try {
      if (!formState.name) return
      const todo = { ...formState }
      setFormState(initialState)
      const newTodoData = await API.graphql(graphqlOperation(createTodo, { input: todo }))
      const newTodo = newTodoData.data.createTodo
      setTodos([newTodo, ...todos])
      console.log('created Todo =>', newTodo)
    } catch (err) {
      console.log('error creating todo:', err)
    } finally {
      setIsLoading(false)
    }
  }

  async function _deleteTodo(index) {
    try {
      const todo = todos[index];
      const deletedTodoData = await API.graphql(graphqlOperation(
        deleteTodo,
        {
          input: {
            id: todo.id, _version: todo._version
          }
        }
      ))
      const deletedTodo = deletedTodoData.data.deleteTodo
      console.log("deletedTodo => ", deletedTodo)
      // todos[index] = deletedTodo;
      setTodos([...todos.slice(0, index), deletedTodo, ...todos.slice(index + 1)])
    } catch (err) {
      console.log('error deleting todo:', err)
    }
  }

  return (
    <>
      <div style={styles.container}>
        <Button variation="link" onClick={signOut}>Sign-Out</Button>
        <Flex direction={'column'}>
          <TextField
            onChange={event => setInput('name', event.target.value)}
            value={formState.name}
            placeholder="Name"
          />
          <TextField
            placeholder="Description"
            onChange={event => setInput('description', event.target.value)}
            value={formState.description}
            placeholder="Description"
          />
          <Button
            variation='primary'
            onClick={addTodo}
            isLoading={isLoading} loadingText='Creating...'
          >
            Create Todo
          </Button>
        </Flex>
        {
          todos.map((todo, index) => (
            <div key={todo.id ? todo.id : index} style={styles.todo}>
              <Divider orientation="horizontal" />
              <Flex>
                {todo._deleted !== true &&
                  <Button
                    onClick={() => _deleteTodo(index)}
                  >✔️</Button>
                }
                <p style={todo._deleted === true ? styles.todoNameDeleted : styles.todoName}>
                  {todo.name}
                </p>
              </Flex>
              {todo._deleted !== true &&
                <p style={styles.todoDescription}>{todo.description}</p>
              }
            </div>
          ))
        }
      </div>
    </>
  )
}

const styles = {
  container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: { marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoNameDeleted: { fontSize: 20, fontWeight: 'bold', textDecoration: 'line-through' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

// export default App
export default withAuthenticator(App)